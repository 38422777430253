import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { DocumentEditor } from '@onlyoffice/document-editor-react';

export default function OnlyOfficeEditor() {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');
  const username = searchParams.get('username');
  const email = searchParams.get('email');
  const inputFileType = searchParams.get('inputFileType');
  const newFileUrl = searchParams.get('newFileUrl');
  const key = searchParams.get('key');
  const consoleServerUrl = searchParams.get('consoleServerUrl');

 const forceSave = () => {
 const url = `https://corsproxy.io/?https://onlyoffice-editor-server.australiaeast.cloudapp.azure.com/coauthoring/CommandService.ashx`
    const data = {
        "c": "forcesave",
        "key": key,
  }

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (

    <>
      <DocumentEditor
        id='docxEditor'
        documentServerUrl='https://onlyoffice-editor-server.australiaeast.cloudapp.azure.com'
        events_onDocumentReady={() => {
          window.documentReady = true;
        }}
        config={{
          document: {
            fileType: 'docxf',
            title: title,
            url: decodeURIComponent(newFileUrl),
            key: key
          },
          documentType: 'word',
          editorConfig: {
            callbackUrl: `${decodeURIComponent(consoleServerUrl)}/api/Collateral/HandleOnlyOfficeRequest?title=${title}&username=${username}&inputFileType=${inputFileType}&newFileUrl=${newFileUrl}&key=${key}&email=${email}`,
            user: {
              id: email,
              name: username,
            },
          },
        }}
        height='800px'
      />

      <button id="onlyoffice-editor-forcesave" onClick={forceSave}> Force Save</button>
    </>
  );
}
